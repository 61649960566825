import styled from '@emotion/styled';
import { Permission } from './Permission';
import { IconButton, Input, Loader } from '@plexxis/ui';
import { PlusIcon } from '@radix-ui/react-icons';
import { useEffect, useRef, useState } from 'react';
import { DriveFile, DrivePermission } from './types/google-types';
import {
  useDriveControllerRemovePermissionsMutation,
  useDriveControllerAddPermissionsMutation,
} from '../api/imports-api/enhanced-api';
import { AddRowsInput } from './AddRowsInput';
import { useSheetsControllerAddRowsMutation } from '../api/imports-api/imports-api';
import { getParsedLoginDetails } from './utils/getParsedLoginDetails';

export interface PermissionEditorProps {
  file: DriveFile;
}

const StyledPermissionEditor = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  ...props.theme.typography.body_m,
  color: props.theme.palette.text.default,
  whiteSpace: 'nowrap',
  '& h3': {
    color: props.theme.palette.text.sixty,
  },
}));
const StyledList = styled.ul((props) => ({
  padding: 0,
  margin: '0 0 20px 0',
}));
const StyledAddWriter = styled.div((props) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: 10,
}));
export const PermissionEditor = ({ file }: PermissionEditorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [rowsInputValue, setRowsInputValue] = useState('');
  const [localPermissions, setLocalPermissions] = useState<DrivePermission[]>(file.permissions!);

  const [addPermission, { isLoading: addPermissionLoading }] = useDriveControllerAddPermissionsMutation();
  const [
    deletePermission,
    { originalArgs: deletePermissionArgs, isLoading: deletePermissionLoading, error: deletePermissionError },
  ] = useDriveControllerRemovePermissionsMutation();
  const [addRows, { isLoading: addRowsLoading }] = useSheetsControllerAddRowsMutation();

  const handleAdd = async () => {
    if (addPermissionLoading) return;
    if (inputRef.current?.checkValidity() && inputValue && file.id) {
      await addPermission({ permissionsDto: { fileId: file.id, emails: [inputValue] } });
      setInputValue('');
    }
  };
  const handleRowsAdd = async () => {
    if (addRowsLoading) return;
    const numberOfRows = Number(rowsInputValue);
    if (numberOfRows > 0 && file.id) {
      await addRows({ addRowsDto: { spreadsheetId: file.id, numberOfRows } });
      setRowsInputValue('');
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAdd();
    }
  };
  const handleDelete = async (id: string) => {
    if (deletePermissionLoading) return;
    if (file.id) {
      //optimistic update
      try {
        await deletePermission({ permissionsDto: { fileId: file.id, emails: [id] } });
        setLocalPermissions(localPermissions.filter((permission) => permission.id !== id));
      } catch (error) {
        console.log(error);
        setLocalPermissions(file.permissions!);
      }
    }
  };

  useEffect(() => {
    setLocalPermissions(file.permissions!);
  }, [file]);

  useEffect(() => {
    //set default email address to logged in user
    const loginDetails = getParsedLoginDetails();
    if (!loginDetails) return;

    const { email } = loginDetails;

    if (!localPermissions.find((permission) => permission.emailAddress === email)) {
      setInputValue(email);
    }
  }, []);

  return (
    <StyledPermissionEditor>
      <h2 style={{ whiteSpace: 'normal' }}>{file.name}</h2>
      <h3>People</h3>
      <StyledList>
        {localPermissions.map((item: DrivePermission) => {
          const thisPermissionLoading =
            deletePermissionArgs?.permissionsDto.emails[0] === item.id && deletePermissionLoading;
          if (item.role !== 'owner')
            return (
              <Permission permission={item} key={item.id} handleDelete={handleDelete} loading={thisPermissionLoading} />
            );
        })}
      </StyledList>
      <StyledAddWriter>
        <Input
          label="Add Writer"
          placeholder="Enter Email..."
          type="email"
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <IconButton
          children={addPermissionLoading ? <Loader size={'s'} /> : <PlusIcon />}
          size={28}
          style={{ marginBottom: 4 }}
          onClick={handleAdd}
        />
      </StyledAddWriter>
      <StyledAddWriter>
        <AddRowsInput inputValue={rowsInputValue} setInputValue={setRowsInputValue} />
        <IconButton
          children={addRowsLoading ? <Loader size={'s'} /> : <PlusIcon />}
          size={28}
          style={{ marginBottom: 4 }}
          onClick={handleRowsAdd}
        />
      </StyledAddWriter>
    </StyledPermissionEditor>
  );
};
