import styled from '@emotion/styled';
import { UserMenu } from './UserMenu';
import { ImportoiseLogo } from './SVGs/ImportoiseLogo';
import { getParsedLoginDetails } from './utils/getParsedLoginDetails';

const StyledHeader = styled.header((props) => ({
  ...props.theme.typography.headline_2,
  padding: '10px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'min-content',
  '& h2': {
    color: props.theme.palette.text.default,
    margin: 0,
  },
}));
const StyledLeft = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
}));
const StyledRight = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
}));

export const Header = () => {
  const userInfo = getParsedLoginDetails();

  const { firstName, lastName, profileImage } = userInfo || { firstName: 'P', lastName: 'S', profileImage: '' };

  return (
    <StyledHeader>
      <StyledLeft>
        <ImportoiseLogo />
        <h2>Import Tool</h2>
      </StyledLeft>
      <StyledRight>
        <UserMenu firstName={firstName || ''} lastName={lastName || ''} avatarUrl={profileImage}></UserMenu>
      </StyledRight>
    </StyledHeader>
  );
};
