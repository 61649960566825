import { getStoredAuthState } from '@plexxis/react-auth-provider';

type LoginDetails = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  iat: number;
  exp: number;
};

export const getParsedLoginDetails = (): LoginDetails | undefined => {
  let parsedLoginDetails;
  try {
    const authState = getStoredAuthState();
    if (authState.status === 'loggedIn') {
      const { loginDetails } = authState;

      parsedLoginDetails = parseLoginDetails(loginDetails.id);
    }
  } catch (e) {
    console.log('Error fetching login details for header');
    return;
  }
  return parsedLoginDetails;
};

// Utility function to safely decode base64URL string
const base64UrlDecode = (str: string) => {
  // Replace non-url compatible chars with base64 standard chars
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');

  // Decode Base64 string and parse JSON
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const parseLoginDetails = (jwt: string): LoginDetails => {
  const base64UrlPayload = jwt.split('.')[1];
  const decodedPayload: LoginDetails = base64UrlDecode(base64UrlPayload);

  return decodedPayload;
};
