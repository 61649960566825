import styled from '@emotion/styled';
import { Checkbox, Dialog, Input, Loader, Select, Wizard, WizardPage } from '@plexxis/ui';
import { useEffect, useState } from 'react';
import { ListInput } from './ListInput';
import {
  useDriveControllerCloneTemplatesMutation,
  useDriveControllerGetTemplatesQuery,
  useMetadataControllerFindAllQuery,
} from '../api/imports-api/imports-api';
import { useDriveControllerAddPermissionsMutation } from '../api/imports-api/enhanced-api';

import { DriveFile, DriveFileList } from './types/google-types';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { ImportoiseLogo } from './SVGs/ImportoiseLogo';
import { toast } from 'react-toastify';
import { useTemplatesByModule } from './hooks/useTemplatesByModule';
import { getParsedLoginDetails } from './utils/getParsedLoginDetails';

export interface FileWizardProps {
  setOpen: (bool: boolean) => void;
  open: boolean;
}

interface TemplateObject {
  id: string;
  name: string;
  country?: string;
  module?: string;
}

const Flex = styled.div(() => ({
  display: 'flex',
  gap: '20px',
}));

const StyledTemplatePage = styled(Flex)((props) => ({
  color: props.theme.palette.text.default,
  ...props.theme.typography.body_l,
}));

const StyledSelects = styled(Flex)(() => ({
  padding: '20px 0',
}));

const StyledHeader = styled.h3((props) => ({
  color: props.theme.palette.text.default,
  ...props.theme.typography.headline_1,
}));

const StyledTemplateList = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  height: '500px',
  overflow: 'scroll',
  padding: '5px',
  border: `1px solid ${props.theme.palette.primary.main}`,
  borderRadius: '5px',
}));

const StyledTemplate = styled.div(() => ({
  display: 'flex',
  gap: '5px',
}));

const StyledLeft = styled(Flex)(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const StyledRight = styled(Flex)(() => ({
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '100%',
}));

const StyledSelectedTemplatesHeader = styled(Flex)((props) => ({
  ...props.theme.typography.headline_3,
}));

const StyledSelectedTemplate = styled.div(() => ({
  cursor: 'pointer',
  ':hover': { textDecoration: 'line-through' },
}));

const StyledPageContainer = styled.div(() => ({
  padding: '20px',
}));
const StyledPermissionsPage = styled(Flex)(() => ({
  flexDirection: 'column',
  alignItems: 'center',
}));
const StyledConfirmationPage = styled.div(() => ({
  flexDirection: 'column',
  padding: '0 20px',
}));

const StyledWarningMessage = styled.div((props) => ({
  color: props.theme.palette.primary.accent,
}));

const StyledLoader = styled(Flex)((props) => ({
  ...props.theme.typography.body_l,
  color: props.theme.palette.text.default,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
}));

export const FileWizard = ({ open, setOpen }: FileWizardProps) => {
  const [templateObjectOptions, setTemplateObjectOptions] = useState<TemplateObject[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [module, setModule] = useState<string | null>('ALL');

  const [filterValue, setFilterValue] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const { data } = useDriveControllerGetTemplatesQuery();
  const [cloneTemplates] = useDriveControllerCloneTemplatesMutation();
  const [addPermissions] = useDriveControllerAddPermissionsMutation();
  const { moduleOptions } = useTemplatesByModule();

  const loginDetails = getParsedLoginDetails();
  const defaultEmails = loginDetails?.email ? [loginDetails.email] : [];

  const [emails, setEmails] = useState<string[]>(defaultEmails);

  const { data: metadata } = useMetadataControllerFindAllQuery();

  const companyName = metadata?.companyName || '';

  useEffect(() => {
    let templateObjects: TemplateObject[] = [];
    if (data) {
      const files = (data as DriveFileList).files || [];
      files.forEach((file: DriveFile) => {
        templateObjects.push({
          id: file.id,
          name: file.name,
          country: file.properties?.country,
          module: file.properties?.module,
        });
      });
      setTemplateObjectOptions(templateObjects);
    }
  }, [data]);

  const currentOptions = templateObjectOptions
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .filter((e) => {
      if (module === 'ALL') return true;
      return e.module === module;
    })
    .filter((e) => {
      if (filterValue === '') return true;
      return e.name.toLowerCase().includes(filterValue.toLowerCase());
    });

  const handleSubmit = async () => {
    if (selectedTemplates.length) {
      setSubmitting(true);
      setLoadingMessage('Creating templates...');
      try {
        const fileIds = await cloneTemplates({
          cloneTemplatesDto: { templateIds: selectedTemplates },
        }).unwrap();
        if (emails.length) {
          setLoadingMessage('Adding permissions...');
          const loadingTemplates = fileIds.map(async (fileId) => {
            return await addPermissions({ permissionsDto: { fileId, emails: emails } });
          });
          await Promise.all(loadingTemplates);
        }
        toast.success('Successfully created templates');
        setLoadingMessage(`Complete!`);
        setSubmitting(false);
        setOpen(false);
        setEmails(defaultEmails);
        setSelectedTemplates([]);
      } catch {
        toast.error('Error creating templates');
        setSubmitting(false);
        setOpen(false);
        setEmails(defaultEmails);
        setSelectedTemplates([]);
      }
    }
  };

  const TemplatesPage = (
    <StyledPageContainer onSubmit={handleSubmit}>
      <StyledHeader>Select Templates</StyledHeader>
      <StyledTemplatePage>
        <StyledLeft>
          <StyledSelects>
            <Select
              options={moduleOptions}
              value={moduleOptions.find((el) => el.value === module)}
              onChange={(event) => {
                setModule(event?.value ?? null);
              }}
              required
            />
            <Input
              icon={<MagnifyingGlassIcon />}
              name="filter"
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              placeholder="Filter Templates..."
              type="text"
              value={filterValue}
            />
          </StyledSelects>
          <StyledTemplate style={{ padding: '5px' }}>
            <Checkbox
              name={'Select All'}
              checked={currentOptions.every((e) => selectedTemplates.includes(e.id))}
              onCheckedChange={(isChecked) => {
                const currentIds = currentOptions.map((e) => e.id);
                if (isChecked) {
                  // add all current ids to selected templates if it doesnt already exist
                  setSelectedTemplates([
                    ...selectedTemplates,
                    ...currentIds.filter((el) => !selectedTemplates.includes(el)),
                  ]);
                } else {
                  setSelectedTemplates(selectedTemplates.filter((el) => !currentIds.includes(el)));
                }
              }}
              style={{ width: '20px', height: '20px' }}
            />
            Select All
          </StyledTemplate>
          <StyledTemplateList>
            {currentOptions.map((e) => {
              const checked = selectedTemplates.includes(e.id);
              return (
                <StyledTemplate key={e.id}>
                  <Checkbox
                    name={e.name}
                    key={e.id}
                    checked={checked}
                    onCheckedChange={(isChecked) => {
                      if (isChecked) {
                        if (!selectedTemplates.includes(e.id)) setSelectedTemplates([...selectedTemplates, e.id]);
                      } else {
                        setSelectedTemplates(selectedTemplates.filter((el) => el !== e.id));
                      }
                    }}
                    style={{ width: '20px', height: '20px' }}
                  />
                  {e.name}
                </StyledTemplate>
              );
            })}
          </StyledTemplateList>
        </StyledLeft>
        <StyledRight>
          <StyledSelectedTemplatesHeader>Selected Templates</StyledSelectedTemplatesHeader>
          <StyledTemplateList>
            {selectedTemplates
              .sort((a, b) => {
                const aName = templateObjectOptions.find((el) => el.id === a)?.name || '';
                const bName = templateObjectOptions.find((el) => el.id === b)?.name || '';
                return aName < bName ? -1 : 1;
              })
              .map((e) => {
                const template = templateObjectOptions.find((el) => el.id === e);
                return (
                  <StyledSelectedTemplate
                    key={e}
                    onClick={() => {
                      setSelectedTemplates(selectedTemplates.filter((el) => el !== e));
                    }}
                  >
                    {template?.name}
                  </StyledSelectedTemplate>
                );
              })}
          </StyledTemplateList>
        </StyledRight>
      </StyledTemplatePage>
    </StyledPageContainer>
  );

  const PermissionsPage = (
    <StyledPageContainer>
      <StyledPermissionsPage>
        <StyledHeader>Add editors to all files</StyledHeader>
        <ListInput items={emails} setItems={setEmails} placeholder="Enter email address..." />
      </StyledPermissionsPage>
    </StyledPageContainer>
  );
  const ConfirmationPage = (
    <StyledPageContainer>
      <StyledConfirmationPage>
        <StyledHeader>Confirmation</StyledHeader>
        <h2>You are about to clone the following templates for {companyName}:</h2>
        <ul style={{ maxHeight: 300, overflow: 'auto' }}>
          {selectedTemplates.map((e) => {
            const template = templateObjectOptions.find((el) => el.id === e);
            return <li key={e}>{template?.name}</li>;
          })}
        </ul>
        {emails.length > 0 ? (
          <>
            <h3>With the following editors:</h3>
            <ul style={{ maxHeight: 200, overflow: 'auto' }}>
              {emails.map((e) => {
                return <li key={e}>{e}</li>;
              })}
            </ul>
            <StyledWarningMessage>
              <h4>
                Upon confirmation, editors will immediately receive an email notification and gain access to the listed
                files for editing. Individual permissions can be added or removed later through each file's Permissions
                dialog.
              </h4>
            </StyledWarningMessage>
          </>
        ) : (
          <StyledWarningMessage>
            <h4>
              You have not yet added any universal editors. It is reccomended to add any universal permissions on the
              previous page. Otherwise, you will have to manually add those permissions to each file.
            </h4>
          </StyledWarningMessage>
        )}
        <StyledWarningMessage>
          <h2>Are you sure you want to continue? This action cannot be undone.</h2>
        </StyledWarningMessage>
      </StyledConfirmationPage>
    </StyledPageContainer>
  );
  return (
    <>
      <Wizard
        open={open}
        onSubmit={() => {
          handleSubmit();
        }}
        onClose={() => {
          setOpen(false);
          setEmails(defaultEmails);
          setSelectedTemplates([]);
        }}
        setOpen={setOpen}
        pages={['Templates', 'Permissions', 'Confirmation']}
        title={'Import Wizard - ' + companyName}
        width="1000px"
        height="1000px"
        icon={<ImportoiseLogo />}
      >
        <WizardPage page="Templates" children={TemplatesPage} disableNext={!selectedTemplates.length} />
        <WizardPage page="Permissions" children={PermissionsPage} />
        <WizardPage page="Confirmation" children={ConfirmationPage} />
      </Wizard>
      <Dialog
        open={submitting}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          padding: '20px',
        }}
      >
        <StyledLoader>
          <Loader />
          {loadingMessage}
        </StyledLoader>
      </Dialog>
    </>
  );
};
