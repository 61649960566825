import styled from '@emotion/styled';
import { Avatar, Actions, ActionItem, Dialog } from '@plexxis/ui';
import { ActivityLogIcon, ExitIcon, MagicWandIcon, MixerHorizontalIcon, ResetIcon } from '@radix-ui/react-icons';
import { useContext, useState } from 'react';
import { ThemeSelector } from './ThemeSelector';
import { LogsContainer } from './LogsContainer';
import { useDispatch } from 'react-redux';
import { setSelectedFile } from '../selectedFileSlice';
import { emptySplitApi } from '../api/imports-api/importsBaseApi';
import { AdminPanel } from './AdminPanel';
import { AuthContext, useAuth } from '@plexxis/react-auth-provider';

interface UserMenuProps {
  firstName: string;
  lastName: string;
  avatarUrl?: string;
}

const StyledUserMenu = styled.div((props) => ({
  background: props.theme.palette.menu.main,
  display: 'flex',
  alignItems: 'center',
  padding: '5px 10px',
  borderRadius: '4px',
  width: 'min-content',
  gap: 10,
}));
const StyledUserName = styled.h3((props) => ({
  ...props.theme.typography.headline_3,
  color: props.theme.palette.primary.contrast,
  margin: 0,
  whiteSpace: 'nowrap',
}));
export const UserMenu = ({ firstName, lastName, avatarUrl }: UserMenuProps) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [adminPanelOpen, setAdminPanelOpen] = useState(false);
  const authContext = useContext(AuthContext);

  //please lord forgive me for my sin
  //replace this when we set up proper permission system from PAS
  const isAdmin =
    (firstName == 'Cory' && lastName == 'McIver') ||
    (firstName == 'Parker' && lastName == 'Swinton') ||
    (firstName == 'Elizabeth' && lastName == 'Boyd');

  return (
    <StyledUserMenu>
      <StyledUserName>
        {firstName} {lastName}
      </StyledUserName>
      <Avatar firstName={firstName} lastName={lastName} pictureUrl={avatarUrl} size={30}></Avatar>
      <Actions size={24} inverted={true} styles={{ content: { zIndex: 100 } }}>
        <ActionItem
          label="Logs"
          icon={<ActivityLogIcon />}
          onClick={() => {
            setLogsOpen((prev) => !prev);
          }}
        />
        <ActionItem
          label="Settings"
          icon={<MixerHorizontalIcon />}
          onClick={() => {
            setSettingsOpen((prev) => !prev);
          }}
        />
        {isAdmin && (
          <ActionItem
            label="Admin Panel"
            icon={<MagicWandIcon />}
            onClick={() => {
              setAdminPanelOpen((prev) => !prev);
            }}
          />
        )}

        <ActionItem label="Logout" icon={<ExitIcon />} onClick={authContext.logout} />
      </Actions>
      <Dialog open={settingsOpen} onOpenChange={setSettingsOpen} height={'min-content'}>
        <ThemeSelector />
      </Dialog>
      <Dialog open={logsOpen} onOpenChange={setLogsOpen} style={{ width: 'fit-content' }}>
        <LogsContainer />
      </Dialog>
      {isAdmin && (
        <Dialog open={adminPanelOpen} onOpenChange={setAdminPanelOpen} style={{ width: 'fit-content' }}>
          <AdminPanel />
        </Dialog>
      )}
    </StyledUserMenu>
  );
};
