import styled from '@emotion/styled';
import { ImportLog, PermissionLog } from '../api/imports-api/imports-api';
import { useMemo, useRef, useState } from 'react';
import { ColumnDef } from '@tanstack/table-core';
import { Button, Input, Table, Tabs } from '@plexxis/ui';
import { usePrepareLogColumns } from './hooks/usePrepareLogColumns';
import { ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import {
  useMetricsControllerGetImportLogsQuery,
  useMetricsControllerGetPermissionLogsQuery,
} from '../api/imports-api/enhanced-api';

const StyledWrapper = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 5px',
  gap: '10px',
  overflowX: 'scroll',
  color: props.theme.palette.text.default,
}));

const StyledLogs = styled.div(() => ({
  width: '1200px',
}));

const TableWrapper = styled.div(() => ({
  // Remove artifacts from ui kit's table
  height: '55vh',
  overflowY: 'auto',

  tbody: {
    '&:before': {
      content: 'none',
    },
    overflowX: 'hidden',
  },
  td: {
    '&:first-of-type': {
      padding: '0px',
    },
  },
  thead: {
    position: 'sticky',
    height: '40px',
    top: '0',
    boxShadow: '0px -1px 0px 1px lightgray',
  },
}));

const StyledFilters = styled.div(() => ({
  display: 'flex',
  gap: '1rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '0.75rem',
}));

const StyledPaginator = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
  gap: '1rem',
}));

const tabs = [
  {
    label: 'Imports',
    value: 'imports',
  },
  {
    label: 'Permissions',
    value: 'permissions',
  },
];

const headers = {
  imports: ['templateCode', 'eventType', 'user', 'date', 'notes', 'signee'],
  permissions: ['action', 'user', 'date', 'fileId', 'email'],
};

export const LogsContainer = () => {
  const [tab, setTab] = useState<keyof typeof headers>('imports');
  const [pagination, setPagination] = useState({ page: 1, pageSize: 100 });
  const { data: importLogs } = useMetricsControllerGetImportLogsQuery({
    ...pagination,
  });
  const { data: permissionLogs } = useMetricsControllerGetPermissionLogsQuery({
    ...pagination,
  });

  const logs = tab === 'imports' ? importLogs : permissionLogs;
  const tableAnchor = useRef<HTMLDivElement>(null);

  const [filterValue, setFilterValue] = useState('');

  const tableHeaders = usePrepareLogColumns(headers[tab]);
  const columns = useMemo((): ColumnDef<ImportLog | PermissionLog>[] => tableHeaders, [tableHeaders]);

  return (
    <StyledWrapper>
      <Tabs
        value={tab}
        tabs={tabs}
        onValueChange={(val) => {
          setTab(val as keyof typeof headers);
          setPagination({ ...pagination, page: 1 });
          setFilterValue('');
        }}
      ></Tabs>
      <StyledFilters>
        <Input
          placeholder="Search Page..."
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          styles={{ wrapper: { width: '80%', marginLeft: 'auto' } }}
          icon={<MagnifyingGlassIcon />}
        />
      </StyledFilters>
      <StyledLogs>
        {logs && (
          <TableWrapper>
            <Table
              data={logs}
              columns={columns}
              styles={{
                body: { overflowY: 'auto', scrollbarGutter: 'stable' },

                headCell: {
                  borderRight: '1px solid lightgray',
                  borderCollapse: 'separate',
                  textAlign: 'center',
                  padding: '0px 6px',
                },
                headRow: { width: 'calc(100% - 6px)' },
                row: {
                  width: '100%',
                  overflowX: 'visible',
                },
                cell: {
                  border: '1px solid lightgray',
                  borderCollapse: 'separate',
                },
                table: {
                  overflowX: 'visible',
                },
              }}
              selected={[]}
              tableState={{ globalFilter: filterValue }}
              extraTopNode={<div ref={tableAnchor} style={{ visibility: 'hidden' }}></div>}
            ></Table>
          </TableWrapper>
        )}
      </StyledLogs>
      <StyledPaginator>
        <Button
          disabled={pagination.page === 1}
          onClick={() => {
            setPagination({ ...pagination, page: Math.max(1, pagination.page - 1) });
            tableAnchor?.current?.scrollIntoView({ block: 'center' });
          }}
          icon={<ChevronLeftIcon />}
        />
        <div>{pagination.page}</div>
        <Button
          disabled={(importLogs?.length || 0) < pagination.pageSize}
          onClick={() => {
            setPagination({ ...pagination, page: pagination.page + 1 });
            tableAnchor?.current?.scrollIntoView({ block: 'center' });
          }}
          icon={<ChevronRightIcon />}
        />
      </StyledPaginator>
    </StyledWrapper>
  );
};
