import styled from '@emotion/styled';
import { Sidebar, SidebarItem } from '@plexxis/ui';
import { Header } from './Header';
import { useState } from 'react';
import { TabsContainer } from './TabsContainer';
import { ClosedLogo, PlexxisLogo } from './SVGs';
import { FileTextIcon } from '@radix-ui/react-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledLayout = styled.div((props) => ({
  ...props.theme.typography.body_s,
  background: props.theme.palette.background.default,
  padding: 0,
  margin: 0,
  width: '100%',
  height: '100vh',
  display: 'flex',
}));

const StyledContent = styled.div(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
const SidebarHeader = styled.div((props) => ({
  color: props.theme.palette.primary.contrast,
  fontSize: '3rem',
  display: 'flex',
  width: '100%',
  height: '3rem',
  justifyContent: 'center',
  marginBottom: '2rem',
}));
export const Layout = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState('Imports');
  return (
    <StyledLayout>
      <Sidebar
        pinned={open}
        setPinned={setOpen}
        active={active}
        headerOpen={
          <SidebarHeader>
            <PlexxisLogo />
          </SidebarHeader>
        }
        headerClosed={
          <SidebarHeader>
            <ClosedLogo />
          </SidebarHeader>
        }
      >
        <SidebarItem icon={<FileTextIcon />} title="Imports" onClick={setActive} />
      </Sidebar>
      <StyledContent>
        <Header />
        <TabsContainer />
      </StyledContent>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </StyledLayout>
  );
};
