import styled from '@emotion/styled';
import { Tabs } from '@plexxis/ui';
import { FileContainer } from './FileContainer';
import { ValidatorContainer } from './ValidatorContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setTab } from '../tabSlice';
import { MappingContainer } from './MappingContainer';
import { useEffect } from 'react';
import { useMetricsControllerUpdateUserInfoMutation } from '../api/imports-api/imports-api';
import { getParsedLoginDetails } from './utils/getParsedLoginDetails';

const StyledTabsContainer = styled.div((props) => ({
  height: '100%',
  margin: '20px',
  boxShadow: props.theme.shadows.medium,
  borderRadius: '5px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
}));

export const TabsContainer = () => {
  const value = useSelector((state: RootState) => state.tab);
  const dispatch = useDispatch();
  const [sendUserInfo] = useMetricsControllerUpdateUserInfoMutation();

  useEffect(() => {
    const storedDetails = getParsedLoginDetails();

    if (storedDetails) {
      sendUserInfo({
        userInfoDto: {
          userId: storedDetails.id,
          firstName: storedDetails.firstName,
          lastName: storedDetails.lastName,
        },
      }).catch((error) => console.error(error));
    }
  }, []);

  const tabs = [
    {
      label: 'Files',
      value: 'files',
      content: <FileContainer />,
    },
    {
      label: 'Validate',
      value: 'validate',
      content: <ValidatorContainer />,
    },
    {
      label: 'Mappings',
      value: 'mappings',
      content: <MappingContainer />,
    },
  ];
  return (
    <StyledTabsContainer>
      <Tabs
        value={value}
        tabs={tabs}
        onValueChange={(val) => {
          dispatch(setTab(val));
        }}
      ></Tabs>
    </StyledTabsContainer>
  );
};
