export const ClosedLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="1000 900 550 2000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <g id="XX" transform="matrix(4.16667,0,0,4.16667,0,0)">
      <g transform="matrix(0,-1,-1,0,306.827,220.332)">
        <path
          d="M-175.02,-175.012C-271.66,-175.012 -350.045,-96.657 -350.045,0.003C-350.045,96.673 -271.66,175.02 -175.02,175.02C-78.378,175.02 0.013,96.673 0.013,0.003C0.013,-96.657 -78.378,-175.012 -175.02,-175.012"
          fill="rgb(47,44,45)"
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,229.12,468.065)">
        <path
          d="M0,-72.739L-57.204,0L-40.229,0L8.49,-61.965L57.217,0L74.213,0L16.999,-72.739L0,-72.739Z"
          fill="rgb(248,152,28)"
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,368.144,468.065)">
        <path
          d="M0,-72.739L-57.206,0L-40.207,0L8.509,-61.965L57.206,0L74.224,0L16.999,-72.739L0,-72.739Z"
          fill="rgb(0,174,239)"
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,246.119,321.223)">
        <path
          d="M0,74.103L58.279,0L41.262,0L-8.509,63.297L-58.274,0L-75.275,0L-16.999,74.103L0,74.103Z"
          fill="rgb(237,28,36)"
          fillRule="nonzero"
        />
      </g>
      <g transform="matrix(1,0,0,1,385.143,321.223)">
        <path
          d="M0,74.103L58.279,0L41.28,0L-8.49,63.297L-58.271,0L-75.278,0L-16.999,74.103L0,74.103Z"
          fill="rgb(92,188,104)"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
