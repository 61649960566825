import { AuthContext, useAuth } from '@plexxis/react-auth-provider';
import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Loader } from '@plexxis/ui';

const StyledWrapper = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100%',
  backgroundColor: props.theme.palette.menu.main,
  alignItems: 'center',
}));

const StyledModal = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  height: '25%',
  marginTop: '25%',
  gap: '1rem',
  padding: '1rem',
  borderRadius: '4px',
  backgroundColor: props.theme.palette.menu.contrast,
  color: props.theme.palette.text.default,
  ...props.theme.typography.headline_5,
}));

const StyledHeadline = styled.h1((props) => ({
  ...props.theme.typography.headline_1,
}));

export const LoggedInCheckpoint: any = ({ children }: { children: JSX.Element }) => {
  const { status } = useAuth();
  const { logout } = useContext(AuthContext);
  const [idle, setIdle] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIdle(true);
    }, 5000);

    return () => {
      setIdle(false);
    };
  }, [status]);

  if (status === 'loggedIn') {
    return children;
  } else {
    return (
      <StyledWrapper>
        <StyledModal>
          {idle ? (
            <>
              <StyledHeadline>Authentication Error</StyledHeadline>
              An error occurred while trying to authenticate. Please try logging in again.
              <Button style={{ marginTop: 'auto' }} onClick={logout}>
                Log in
              </Button>
            </>
          ) : (
            <Loader />
          )}
        </StyledModal>
      </StyledWrapper>
    );
  }
};
