import styled from '@emotion/styled';
import { Button } from '@plexxis/ui';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { toast } from 'react-toastify';
import { useDriveControllerDeleteFilesMutation } from '../api/imports-api/imports-api';
import { DriveFile } from './types/google-types';

const StyledDialogOptions = styled.div(() => ({
  display: 'flex',
  width: '100%',
  padding: '5px',
  borderRadius: '0px 0px 5px 5px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
}));
const StyledDialog = styled.div((props) => ({
  ...props.theme.typography.headline_5,
  color: props.theme.palette.text.default,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  borderRadius: 5,
  paddingTop: 5,
  width: '400px',
}));
const StyledWarning = styled.div((props) => ({
  color: props.theme.palette.status.warning,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '15px',
}));

export const DeleteFileItemWarning = ({ file, setOpen }: { file: DriveFile; setOpen: (open: boolean) => void }) => {
  const [deleteFiles, { isLoading: isDeleting }] = useDriveControllerDeleteFilesMutation();

  return (
    <StyledDialog>
      <div style={{ padding: '0px 15px' }}>
        <StyledWarning>
          <ExclamationTriangleIcon width={25} height={25} />
          <h3>Delete file</h3>
        </StyledWarning>
        <p>You are about to delete the file: </p>
        <p>
          <b>{file.name}</b>
        </p>
        <p>
          <b>All data stored in this document will be lost. This action cannot be undone.</b>
        </p>
      </div>
      <p>
        <b>Are you sure?</b>
      </p>
      <StyledDialogOptions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={async () => {
            try {
              await deleteFiles({ filesDeleteDto: { fileIds: [file.id] } }).unwrap();
              toast.info('File succesfully deleted');
            } catch (error) {
              toast.error('Error deleting file');
            }
            setOpen(false);
          }}
          loading={isDeleting}
          variant="delete"
        >
          Delete File
        </Button>
      </StyledDialogOptions>
    </StyledDialog>
  );
};
