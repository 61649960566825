import { Tooltip } from '@plexxis/ui';
import { Progress, StatusColor } from './hooks/useProgressManager';

export const LoadingBar = ({ progress }: { progress: Progress }) => {
  const title =
    progress.status === 'complete'
      ? 'Import successful!'
      : progress.status === 'error'
      ? 'Import failed'
      : progress.status === 'progress'
      ? `Importing: ${progress.progress}% complete`
      : '';

  return (
    <div>
      <Tooltip title={title}>
        <div
          style={{
            width: '100%',
            height: '0.5rem',
            borderRadius: '0.15rem',
            backgroundColor: 'lightgray',
            border: '0.15rem solid lightgray',
          }}
        >
          <div
            style={{
              width: `${progress.progress}%`,
              borderRadius: '0.15rem',

              height: '100%',
              backgroundColor: StatusColor[progress.status],
              transition: 'width 250ms ease-in-out',
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
};
