import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn(`${action.payload?.status || 'Unknown'} Error: ${action.payload?.data?.message}`);
    if (action.payload?.status === 'FETCH_ERROR') {
      toast.error(`Network Error: unable to connect to API`);
    } else {
      toast.error(`${action.payload?.status || 'Unknown'} Error: ${action.payload?.data?.message}`);
    }
  }

  return next(action);
};
