import { createSlice } from '@reduxjs/toolkit';

const initialState = 'files';

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setTab(_, action) {
      return action.payload;
    },
  },
});

export const { setTab } = tabSlice.actions;
export default tabSlice.reducer;
