import styled from '@emotion/styled';
import { useState } from 'react';
import { Button } from '@plexxis/ui';
import { FileItem } from './FileItem';
import { FileWizard } from './FileWizard';
import { DriveFile, DriveFileList } from './types/google-types';
import { useDriveControllerGetFilesQuery } from '../api/imports-api/enhanced-api';
import { useProgressManager } from './hooks/useProgressManager';
import { useMetadataControllerFindAllQuery } from '../api/imports-api/imports-api';

export const StyledFileContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: '10px',
  height: '100%',
}));

export const MenuOptions = styled.div((props) => ({
  color: props.theme.palette.text.default,
  display: 'grid',
  grid: 'min-content min-content 1fr / 1fr min-content',
  justifyContent: 'space-between',
}));

export const FileHeader = styled.div(({ theme }) => ({
  ...theme.typography.headline_6,
  background: theme.palette.background.default,
  color: theme.palette.text.sixty,
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '1px',
  textTransform: 'uppercase',
  display: 'grid',
  grid: '1fr / 8fr 4fr 4fr 2fr 2fr 1fr 1fr',
  alignItems: 'center',
  textIndent: '10px',
  justifyContent: 'center',
  padding: '8px 0px',
  width: '100%',
  gap: 10,
}));

export const StyledFileList = styled.div(() => ({
  display: 'flex',
  flex: '1 1 0',
  flexDirection: 'column',
  padding: '10px 2px',
  gap: '10px',
  overflow: 'auto',
  marginBottom: '20px',
}));

export const FileContainer = () => {
  const [open, setOpen] = useState(false);

  const { currentData: filesData } = useDriveControllerGetFilesQuery();
  const { data: metadata } = useMetadataControllerFindAllQuery();

  const companyName = metadata?.companyName || '';

  const files = (filesData as DriveFileList)?.files || [];

  const { progressList } = useProgressManager();

  return (
    <StyledFileContainer>
      <MenuOptions>
        <h1>{companyName}</h1>

        <Button onClick={() => setOpen((prev) => !prev)}>Generate Files</Button>
        <FileWizard open={open} setOpen={setOpen}></FileWizard>
      </MenuOptions>
      <FileHeader>
        <div>Name</div>
        <div>Modified</div>
        <div>Created</div>
        <div>Status</div>
      </FileHeader>
      <StyledFileList>
        {files.map((file: DriveFile) => (
          <FileItem file={file} key={file.id} progress={progressList[file.id]}></FileItem>
        ))}
      </StyledFileList>
    </StyledFileContainer>
  );
};
