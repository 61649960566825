import { useRef } from 'react';
import { useLazyCsvControllerGetTemplateCsvsQuery } from '../../api/imports-api/enhanced-api';
import { toast } from 'react-toastify';

export const useDownloadMappings = () => {
  const [getTemplateCsvTrigger, { isLoading, isFetching }] = useLazyCsvControllerGetTemplateCsvsQuery();
  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

  const templateLoading = isLoading || isFetching;

  const handleDownloadMappings = async (templateCode: string) => {
    if (templateLoading) return;
    const toastId = toast.loading('Downloading mappings...');
    try {
      const url = (await getTemplateCsvTrigger({ templateCode }).unwrap()).data;

      if (url) {
        downloadLinkRef.current?.setAttribute('href', url);
        downloadLinkRef.current?.click();
        toast.update(toastId, {
          render: 'Successfully downloaded mappings',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.update(toastId, {
        render: 'Failed to download mappings',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  return { handleDownloadMappings, downloadLinkRef, templateLoading };
};
