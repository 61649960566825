import styled from '@emotion/styled';
import { useState } from 'react';
import { Button, Input, Select } from '@plexxis/ui';
import { DownloadIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useDownloadMappings } from './hooks/useDownloadMappings';
import { useTemplatesByModule } from './hooks/useTemplatesByModule';

export const StyledContainer = styled.div(() => ({
  display: 'flex',
  flex: '1 1 0',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'space-between',
  margin: '10px',
  marginRight: 'auto',
  height: '100%',
  maxWidth: 'min-content',
}));

export const StyledCsvList = styled.div(() => ({
  display: 'flex',
  overflow: ' auto',
  flex: '1 1 0',
  flexDirection: 'column',
  padding: '10px 2px',
  gap: '15px',
  marginBottom: '20px',
}));

export const StyledOptions = styled.div(() => ({
  display: 'flex',
  gap: '20px',
  alignItems: 'flex-end',
}));

export const MappingContainer = () => {
  const [selectedModule, setSelectedModule] = useState<string>('ALL');
  const [lastDownloadedCsv, setLastDownloadedCsv] = useState('');
  const [filterValue, setFilterValue] = useState<string>('');

  const { handleDownloadMappings, downloadLinkRef, templateLoading } = useDownloadMappings();

  const { moduleOptions, templatesByModule } = useTemplatesByModule();
  const filteredTemplatesByModule = templatesByModule
    ? (templatesByModule as { [key: string]: string[] })[selectedModule].filter((e) => {
        if (filterValue === '') return true;
        return e.toLowerCase().includes(filterValue.toLowerCase());
      })
    : undefined;

  return (
    <StyledContainer>
      <StyledOptions>
        <Select
          label={'Select Module'}
          options={moduleOptions}
          value={moduleOptions.find((d) => d.value === selectedModule)}
          onChange={(e) => setSelectedModule(e?.value!)}
          placeholder={'Select a mapping...'}
          isSearchable={true}
        ></Select>
        <Input
          icon={<MagnifyingGlassIcon />}
          name="filter"
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
          placeholder="Filter Templates..."
          type="text"
          value={filterValue}
        />
      </StyledOptions>

      <StyledCsvList>
        {filteredTemplatesByModule &&
          filteredTemplatesByModule.map((template) => (
            <Button
              onClick={() => {
                setLastDownloadedCsv(template);
                handleDownloadMappings(template);
              }}
              loading={template == lastDownloadedCsv && templateLoading}
              key={template}
              style={{
                width: '95%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {template}
              <DownloadIcon></DownloadIcon>
            </Button>
          ))}
      </StyledCsvList>
      <a ref={downloadLinkRef} style={{ display: 'none' }} />
    </StyledContainer>
  );
};
