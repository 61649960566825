import styled from '@emotion/styled';
import { StatusColors } from '@plexxis/ui';
import { CheckIcon, Cross2Icon, ExclamationTriangleIcon } from '@radix-ui/react-icons';

interface AlertButtonProps {
  type: string | undefined;
  fileId: string | undefined;
}

const StyledAlertButton = styled.a((props) => ({
  backgroundColor: props.theme.palette.status[props.type as keyof StatusColors],
  color: props.theme.palette.menu.contrast,
  gap: '10px',
  paddingLeft: '15px',
  paddingRight: '15px',
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  cursor: 'pointer',
  textDecoration: 'none',
}));

const iconType = (type: string) => {
  switch (type) {
    case 'warning':
      return (
        <>
          <ExclamationTriangleIcon />
          There are still errors in the excel sheet.
        </>
      );
    case 'success':
      return (
        <>
          <CheckIcon />
          Validation successful.
        </>
      );
    case 'error':
      return (
        <>
          <Cross2Icon />
          Validation failed. Fix the errors displayed below.
        </>
      );
    default:
      return null;
  }
};

export const AlertButton = ({ type, fileId }: AlertButtonProps) => {
  if (!type) return null;

  return (
    <StyledAlertButton type={type} href={`https://docs.google.com/spreadsheets/d/${fileId}`} target="_blank">
      {iconType(type)}
    </StyledAlertButton>
  );
};
