import { ColumnDef, Tooltip } from '@plexxis/ui';
import { ImportLog, PermissionLog } from '../../api/imports-api/imports-api';

export const usePrepareLogColumns = (headers: string[]): ColumnDef<ImportLog | PermissionLog>[] => {
  const columns = headers.map((header, index) => ({
    accessorFn: (row: any) => row[header],
    id: index.toString(),
    header: () => <span style={{ padding: 'none', margin: 'auto' }}>{header}</span>,
    cell: (props: any) => {
      let value = props.getValue();
      const cellStyle = {
        paddingLeft: '2px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      };
      if (header === 'date') value = new Date(value).toUTCString();

      if (header === 'user')
        return (
          <Tooltip title={value.userId} style={{ zIndex: 10 }}>
            <div style={cellStyle}>{`${value.firstName} ${value.lastName}`}</div>
          </Tooltip>
        );
      return <div style={cellStyle}>{value}</div>;
    },
  }));
  return columns;
};
