import styled from '@emotion/styled';
import { Actions, ActionItem, Dialog } from '@plexxis/ui';
import {
  EyeOpenIcon,
  LockClosedIcon,
  LockOpen2Icon,
  AvatarIcon,
  LockOpen1Icon,
  DownloadIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import { PermissionEditor } from './PermissionEditor';
import { DriveFile } from './types/google-types';
import { useDriveControllerUnlockFileMutation } from '../api/imports-api/imports-api';
import { useDispatch } from 'react-redux';
import { setSelectedFile } from '../selectedFileSlice';
import { setTab } from '../tabSlice';
import { useDownloadMappings } from './hooks/useDownloadMappings';
import { DeleteFileItemWarning } from './DeleteFileItemWarning';
import { Progress } from './hooks/useProgressManager';
import { LoadingBar } from './LoadingBar';
import { toast } from 'react-toastify';

export interface FileItemProps {
  file: DriveFile;
  progress?: Progress;
}

const StyledFileItem = styled.div(({ theme }) => ({
  background: theme.palette.background.default,
  color: theme.palette.text.default,
  display: 'grid',
  grid: '1fr / 8fr 4fr 4fr 2fr 2fr 1fr 1fr',
  alignItems: 'center',
  textIndent: '10px',
  borderRadius: '4px',
  width: '100%',
  gap: 10,
  padding: '8px 0px',
  boxShadow: theme.shadows.small,
}));
const Text = styled.div((props) => ({
  ...props.theme.typography.body_m,
  margin: 0,
  whiteSpace: 'nowrap',
}));
const SubmissionStatus = styled.div<{ statusColor: string }>(({ statusColor, theme }) => ({
  ...theme.typography.body_m,
  color: statusColor,
}));

const StyledLink = styled.a((props) => ({
  ...props.theme.typography.body_m,
  color: props.theme.palette.text.default,
  margin: 0,
  whiteSpace: 'nowrap',
  textDecoration: 'none',
}));

export const FileItem = ({ file, progress }: FileItemProps) => {
  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const { name, id, createdTime, modifiedTime, permissions, appProperties } = file;
  const [unlock] = useDriveControllerUnlockFileMutation();
  const { handleDownloadMappings, downloadLinkRef } = useDownloadMappings();
  const dispatch = useDispatch();

  const locked = appProperties?.locked === 'true';
  const submission = appProperties?.submission;
  const templateCode = appProperties?.templateCode;

  const modified = new Date(modifiedTime!).toLocaleString();
  const created = new Date(createdTime!).toLocaleString();

  const handleUnlock = async (fileId: string) => {
    await unlock({ fileLockDto: { fileId } });
  };
  const pending = submission === 'PENDING';
  const completed = submission === 'COMPLETED';
  const statusColor = pending ? 'red' : completed ? 'green' : '';

  useEffect(() => {
    if (progress) {
      if (progress.status === 'complete') {
        toast.success(name + ' successfully imported!');
      }
      if (progress.status === 'error') {
        toast.error(name + ' import failed');
      }
    }
  }, [progress]);

  return (
    <StyledFileItem>
      <StyledLink href={`https://docs.google.com/spreadsheets/d/${id}`} target="_blank">
        {name}
      </StyledLink>
      <Text>{modified}</Text>
      <Text>{created}</Text>
      <SubmissionStatus statusColor={statusColor}>{appProperties?.submission}</SubmissionStatus>
      {progress ? <LoadingBar progress={progress} /> : <div></div>}
      <div style={{ display: 'flex', justifySelf: 'flex-end', alignSelf: 'center', paddingRight: '0.5rem' }}>
        {locked ? <LockClosedIcon /> : <LockOpen2Icon />}
      </div>
      {!(submission === 'COMPLETED') ? (
        <Actions size={24}>
          {locked && !pending && (
            <ActionItem label="Unlock" icon={<LockOpen1Icon />} onClick={() => handleUnlock(file.id)} />
          )}
          <ActionItem
            label="Validate"
            icon={<EyeOpenIcon />}
            onClick={() => {
              dispatch(setSelectedFile({ fileId: id }));
              dispatch(setTab('validate'));
            }}
          />
          <ActionItem
            label="Mappings"
            icon={<DownloadIcon />}
            onClick={() => handleDownloadMappings(templateCode!)}
            disabled={locked}
          />
          <ActionItem
            label="Permissions"
            icon={<AvatarIcon />}
            onClick={() => setPermissionDialogOpen((prev) => !prev)}
          />
          {!pending && !completed && (
            <ActionItem
              label="Delete"
              icon={<TrashIcon />}
              onClick={() => {
                setDeleteFileDialogOpen(true);
              }}
              disabled={locked}
            />
          )}
        </Actions>
      ) : (
        <Actions size={24}>
          <ActionItem
            label="Permissions"
            icon={<AvatarIcon />}
            onClick={() => setPermissionDialogOpen((prev) => !prev)}
          />
        </Actions>
      )}

      <Dialog open={permissionDialogOpen} onOpenChange={setPermissionDialogOpen} maxWidth={'min-content'}>
        <PermissionEditor file={{ name, id, permissions }} />
      </Dialog>
      <Dialog open={deleteFileDialogOpen} onOpenChange={setDeleteFileDialogOpen} maxWidth={'min-content'}>
        <DeleteFileItemWarning file={file} setOpen={setDeleteFileDialogOpen} />
      </Dialog>
      <a ref={downloadLinkRef} style={{ display: 'none' }} />
    </StyledFileItem>
  );
};
