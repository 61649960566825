import styled from '@emotion/styled';
import { allThemes } from '@plexxis/ui';
import { useDispatch } from 'react-redux';
import { setTheme } from '../themeSlice';
interface ButtonProps {
  buttonTheme: keyof typeof allThemes;
}
const StyledThemeSelector = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '30px',
}));
const StyledButton = styled.button<ButtonProps>((props) => ({
  all: 'unset',
  ...props.theme.typography.body_m,
  fontWeight: 'bold',
  border: `1px solid ${allThemes[props.buttonTheme].palette.secondary.main}`,
  backgroundColor: allThemes[props.buttonTheme].palette.secondary.main,
  color: allThemes[props.buttonTheme].palette.secondary.contrast,
  height: 36,
  lineHeight: 'initial',
  boxSizing: 'border-box',
  borderRadius: '4px',
  padding: `0 12px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  cursor: 'pointer',
  whiteSpace: 'pre',
  position: 'relative',
  '&:hover': {
    backgroundColor: allThemes[props.buttonTheme].palette.primary.main,
    borderColor: allThemes[props.buttonTheme].palette.primary.main,
  },
}));
export const ThemeSelector = () => {
  const dispatch = useDispatch();
  const handleClick = (newTheme: string) => {
    dispatch(setTheme(newTheme as keyof typeof allThemes));
    localStorage.setItem('theme', newTheme);
  };
  return (
    <StyledThemeSelector>
      {Object.keys(allThemes).map((item, idx) => (
        <StyledButton key={idx} onClick={() => handleClick(item)} buttonTheme={item as keyof typeof allThemes}>
          {item}
        </StyledButton>
      ))}
    </StyledThemeSelector>
  );
};
