import { emptySplitApi as api } from './importsBaseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerGetDmsToken: build.mutation<AuthControllerGetDmsTokenApiResponse, AuthControllerGetDmsTokenApiArg>({
      query: (queryArg) => ({ url: `/auth/token`, method: 'POST', body: queryArg.getTokenDto }),
    }),
    driveControllerGetFiles: build.query<DriveControllerGetFilesApiResponse, DriveControllerGetFilesApiArg>({
      query: () => ({ url: `/drive` }),
    }),
    driveControllerGetTemplates: build.query<DriveControllerGetTemplatesApiResponse, DriveControllerGetTemplatesApiArg>(
      {
        query: () => ({ url: `/drive/templates` }),
      },
    ),
    driveControllerCloneTemplates: build.mutation<
      DriveControllerCloneTemplatesApiResponse,
      DriveControllerCloneTemplatesApiArg
    >({
      query: (queryArg) => ({ url: `/drive/clone-templates`, method: 'POST', body: queryArg.cloneTemplatesDto }),
    }),
    driveControllerAddPermissions: build.mutation<
      DriveControllerAddPermissionsApiResponse,
      DriveControllerAddPermissionsApiArg
    >({
      query: (queryArg) => ({ url: `/drive/add-permissions`, method: 'POST', body: queryArg.permissionsDto }),
    }),
    driveControllerRemovePermissions: build.mutation<
      DriveControllerRemovePermissionsApiResponse,
      DriveControllerRemovePermissionsApiArg
    >({
      query: (queryArg) => ({ url: `/drive/remove-permissions`, method: 'POST', body: queryArg.permissionsDto }),
    }),
    driveControllerDeleteFiles: build.mutation<DriveControllerDeleteFilesApiResponse, DriveControllerDeleteFilesApiArg>(
      {
        query: (queryArg) => ({ url: `/drive/delete-files`, method: 'POST', body: queryArg.filesDeleteDto }),
      },
    ),
    driveControllerLockFile: build.mutation<DriveControllerLockFileApiResponse, DriveControllerLockFileApiArg>({
      query: (queryArg) => ({ url: `/drive/lock-file`, method: 'POST', body: queryArg.fileLockDto }),
    }),
    driveControllerUnlockFile: build.mutation<DriveControllerUnlockFileApiResponse, DriveControllerUnlockFileApiArg>({
      query: (queryArg) => ({ url: `/drive/unlock-file`, method: 'POST', body: queryArg.fileLockDto }),
    }),
    driveControllerCreateTemplate: build.mutation<
      DriveControllerCreateTemplateApiResponse,
      DriveControllerCreateTemplateApiArg
    >({
      query: (queryArg) => ({ url: `/drive/create-template`, method: 'POST', body: queryArg.createTemplateDto }),
    }),
    driveControllerReplaceTemplate: build.mutation<
      DriveControllerReplaceTemplateApiResponse,
      DriveControllerReplaceTemplateApiArg
    >({
      query: (queryArg) => ({ url: `/drive/replace-template`, method: 'POST', body: queryArg.createTemplateDto }),
    }),
    metricsControllerUpdateUserInfo: build.mutation<
      MetricsControllerUpdateUserInfoApiResponse,
      MetricsControllerUpdateUserInfoApiArg
    >({
      query: (queryArg) => ({ url: `/metrics/user-info`, method: 'POST', body: queryArg.userInfoDto }),
    }),
    metricsControllerGetImportLogs: build.query<
      MetricsControllerGetImportLogsApiResponse,
      MetricsControllerGetImportLogsApiArg
    >({
      query: (queryArg) => ({ url: `/metrics/imports`, params: { page: queryArg.page, pageSize: queryArg.pageSize } }),
    }),
    metricsControllerGetPermissionLogs: build.query<
      MetricsControllerGetPermissionLogsApiResponse,
      MetricsControllerGetPermissionLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/metrics/permissions`,
        params: { page: queryArg.page, pageSize: queryArg.pageSize },
      }),
    }),
    sheetsControllerReadSheetValidation: build.query<
      SheetsControllerReadSheetValidationApiResponse,
      SheetsControllerReadSheetValidationApiArg
    >({
      query: (queryArg) => ({ url: `/sheets/validate-sheet/${queryArg.spreadsheetId}` }),
    }),
    sheetsControllerReadSheet: build.query<SheetsControllerReadSheetApiResponse, SheetsControllerReadSheetApiArg>({
      query: (queryArg) => ({ url: `/sheets/read/${queryArg.spreadsheetId}` }),
    }),
    sheetsControllerAddRows: build.mutation<SheetsControllerAddRowsApiResponse, SheetsControllerAddRowsApiArg>({
      query: (queryArg) => ({ url: `/sheets/add-rows`, method: 'POST', body: queryArg.addRowsDto }),
    }),
    templateSwitchControllerValidate: build.query<
      TemplateSwitchControllerValidateApiResponse,
      TemplateSwitchControllerValidateApiArg
    >({
      query: (queryArg) => ({
        url: `/template-switch`,
        params: {
          fileId: queryArg.fileId,
          notes: queryArg.notes,
          signee: queryArg.signee,
          page: queryArg.page,
          cacheKey: queryArg.cacheKey,
        },
      }),
    }),
    templateSwitchControllerSubmit: build.mutation<
      TemplateSwitchControllerSubmitApiResponse,
      TemplateSwitchControllerSubmitApiArg
    >({
      query: (queryArg) => ({ url: `/template-switch/submit`, method: 'POST', body: queryArg.templateSwitchFetchDto }),
    }),
    templateSwitchControllerCommit: build.mutation<
      TemplateSwitchControllerCommitApiResponse,
      TemplateSwitchControllerCommitApiArg
    >({
      query: (queryArg) => ({ url: `/template-switch/commit`, method: 'POST', body: queryArg.templateSwitchFetchDto }),
    }),
    templateSwitchControllerRollback: build.mutation<
      TemplateSwitchControllerRollbackApiResponse,
      TemplateSwitchControllerRollbackApiArg
    >({
      query: (queryArg) => ({
        url: `/template-switch/rollback`,
        method: 'POST',
        body: queryArg.templateSwitchFetchDto,
      }),
    }),
    csvControllerGetTemplatesByModule: build.query<
      CsvControllerGetTemplatesByModuleApiResponse,
      CsvControllerGetTemplatesByModuleApiArg
    >({
      query: () => ({ url: `/csv` }),
    }),
    csvControllerGetTemplateCsvs: build.query<
      CsvControllerGetTemplateCsvsApiResponse,
      CsvControllerGetTemplateCsvsApiArg
    >({
      query: (queryArg) => ({ url: `/csv/templates/${queryArg.templateCode}` }),
    }),
    dmsControllerFindAll: build.query<DmsControllerFindAllApiResponse, DmsControllerFindAllApiArg>({
      query: () => ({ url: `/dms` }),
    }),
    metadataControllerFindAll: build.query<MetadataControllerFindAllApiResponse, MetadataControllerFindAllApiArg>({
      query: () => ({ url: `/metadata` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerGetDmsTokenApiResponse = /** status 201  */ GetTokenResponseDto;
export type AuthControllerGetDmsTokenApiArg = {
  getTokenDto: GetTokenDto;
};
export type DriveControllerGetFilesApiResponse = /** status 200  */ object;
export type DriveControllerGetFilesApiArg = void;
export type DriveControllerGetTemplatesApiResponse = /** status 200  */ object;
export type DriveControllerGetTemplatesApiArg = void;
export type DriveControllerCloneTemplatesApiResponse = /** status 201  */ string[];
export type DriveControllerCloneTemplatesApiArg = {
  /** Clones templates */
  cloneTemplatesDto: CloneTemplatesDto;
};
export type DriveControllerAddPermissionsApiResponse = unknown;
export type DriveControllerAddPermissionsApiArg = {
  /** Add writer permissions for a given file */
  permissionsDto: PermissionsDto;
};
export type DriveControllerRemovePermissionsApiResponse = unknown;
export type DriveControllerRemovePermissionsApiArg = {
  /** Remove writer permissions for a given file */
  permissionsDto: PermissionsDto;
};
export type DriveControllerDeleteFilesApiResponse = unknown;
export type DriveControllerDeleteFilesApiArg = {
  /** The ID of the sheets you want to delete */
  filesDeleteDto: FilesDeleteDto;
};
export type DriveControllerLockFileApiResponse = /** status 201  */ object[];
export type DriveControllerLockFileApiArg = {
  /** The ID of the file you want to lock */
  fileLockDto: FileLockDto;
};
export type DriveControllerUnlockFileApiResponse = /** status 201  */ object[];
export type DriveControllerUnlockFileApiArg = {
  /** The ID of the file you want to unlock */
  fileLockDto: FileLockDto;
};
export type DriveControllerCreateTemplateApiResponse = /** status 201  */ object;
export type DriveControllerCreateTemplateApiArg = {
  /** The ID of the source file you want to create a new template from */
  createTemplateDto: CreateTemplateDto;
};
export type DriveControllerReplaceTemplateApiResponse = unknown;
export type DriveControllerReplaceTemplateApiArg = {
  /** Endpoint for replacing an existing template. */
  createTemplateDto: CreateTemplateDto;
};
export type MetricsControllerUpdateUserInfoApiResponse = unknown;
export type MetricsControllerUpdateUserInfoApiArg = {
  /** Update User Info */
  userInfoDto: UserInfoDto;
};
export type MetricsControllerGetImportLogsApiResponse = /** status 200  */ ImportLog[];
export type MetricsControllerGetImportLogsApiArg = {
  /** The page number */
  page?: number;
  /** The page size */
  pageSize?: number;
};
export type MetricsControllerGetPermissionLogsApiResponse = /** status 200  */ PermissionLog[];
export type MetricsControllerGetPermissionLogsApiArg = {
  /** The page number */
  page?: number;
  /** The page size */
  pageSize?: number;
};
export type SheetsControllerReadSheetValidationApiResponse = /** status 200  */ ReadSheetValidityDto;
export type SheetsControllerReadSheetValidationApiArg = {
  /** The ID of the file to validate */
  spreadsheetId: string;
};
export type SheetsControllerReadSheetApiResponse = /** status 200  */ ReadSheetDataDto;
export type SheetsControllerReadSheetApiArg = {
  /** The ID of the file to validate */
  spreadsheetId: string;
};
export type SheetsControllerAddRowsApiResponse = unknown;
export type SheetsControllerAddRowsApiArg = {
  addRowsDto: AddRowsDto;
};
export type TemplateSwitchControllerValidateApiResponse = /** status 200  */ TemplateSwitchValidateDto;
export type TemplateSwitchControllerValidateApiArg = {
  /** The ID of the file */
  fileId: string;
  /** The notes of the submission/rollback/commit */
  notes?: string;
  /** The client approving the action */
  signee?: string;
  /** The current page number */
  page?: number;
  /** UUID for cached data */
  cacheKey?: string;
};
export type TemplateSwitchControllerSubmitApiResponse = unknown;
export type TemplateSwitchControllerSubmitApiArg = {
  templateSwitchFetchDto: TemplateSwitchFetchDto;
};
export type TemplateSwitchControllerCommitApiResponse = unknown;
export type TemplateSwitchControllerCommitApiArg = {
  templateSwitchFetchDto: TemplateSwitchFetchDto;
};
export type TemplateSwitchControllerRollbackApiResponse = unknown;
export type TemplateSwitchControllerRollbackApiArg = {
  templateSwitchFetchDto: TemplateSwitchFetchDto;
};
export type CsvControllerGetTemplatesByModuleApiResponse = /** status 200  */ object;
export type CsvControllerGetTemplatesByModuleApiArg = void;
export type CsvControllerGetTemplateCsvsApiResponse = /** status 200  */ CsvLinkDto;
export type CsvControllerGetTemplateCsvsApiArg = {
  templateCode: string;
};
export type DmsControllerFindAllApiResponse = /** status 200  */ CompanyDto[];
export type DmsControllerFindAllApiArg = void;
export type MetadataControllerFindAllApiResponse = /** status 200  */ MetadataDto;
export type MetadataControllerFindAllApiArg = void;
export type GetTokenResponseDto = {
  accessToken: string;
  refreshToken: string;
  id: string;
};
export type GetTokenDto = {
  code: string;
  codeVerifier: string;
};
export type CloneTemplatesDto = {
  templateIds: string[];
};
export type PermissionsDto = {
  fileId: string;
  emails: string[];
};
export type FilesDeleteDto = {
  fileIds: string[];
};
export type FileLockDto = {
  fileId: string;
};
export type CreateTemplateDto = {
  fileId: string;
  name: string;
  templateCode:
    | 'ACTIVITY_CODES_C'
    | 'AP_INVOICES'
    | 'CONTACTS'
    | 'COSTING_CODES'
    | 'CUSTOMERS'
    | 'CUSTOMER_ADDRESSES'
    | 'CUSTOMER_TAGS'
    | 'EMPLOYEES_US'
    | 'EMPLOYEES_DIRECT_DEPOSIT_CA'
    | 'EMPLOYEES_DIRECT_DEPOSIT_US'
    | 'EMPLOYEES_FEDERAL_US'
    | 'EMPLOYEES_PAYROLL_CA'
    | 'EMPLOYEES_PAYROLL_US'
    | 'EMPLOYEES_MAIN_CA'
    | 'EMPLOYEES_MAIN_US'
    | 'EMPLOYEES_SALARY'
    | 'EMPLOYEE_RATE_HISTORY'
    | 'EMPLOYEE_TAGS'
    | 'EMPLOYEE_STATE_TAX_SETUP'
    | 'GL_ACCOUNTS'
    | 'GL_BRANCH_ACCOUNTS'
    | 'GL_TRANSACTION_DETAILS'
    | 'JOBS'
    | 'JOBS_ACCOUNTING'
    | 'JOBS_GENERAL'
    | 'JOBS_RATE'
    | 'JOBS_PAYROLL'
    | 'JOBS_REFERENCE'
    | 'JOBS_REPORTING'
    | 'JOB_EXPENSES'
    | 'JOB_DETAILS'
    | 'JOB_DETAILS_ADDITIONAL'
    | 'JOB_DETAILS_ADJUSTMENT'
    | 'JOB_DETAILS_BUDGET'
    | 'PAYMENTS'
    | 'PAYMENTS_UPDATE'
    | 'PAYROLL_EMP_ITEMS'
    | 'PAYROLL_EMP_ITEMS_PTO_ACCRUAL'
    | 'Q_FLOORS'
    | 'SUPPLIERS'
    | 'SUPPLIERS_UPDATE'
    | 'SUPPLIER_TAGS'
    | 'TAX_AREA_SETUP';
};
export type UserInfoDto = {
  userId: string;
  firstName: string;
  lastName: string;
};
export type User = {
  userId: string;
  firstName: string;
  lastName: string;
};
export type ImportLog = {
  templateCode:
    | 'ACTIVITY_CODES_C'
    | 'AP_INVOICES'
    | 'CONTACTS'
    | 'COSTING_CODES'
    | 'CUSTOMERS'
    | 'CUSTOMER_ADDRESSES'
    | 'CUSTOMER_TAGS'
    | 'EMPLOYEES_US'
    | 'EMPLOYEES_DIRECT_DEPOSIT_CA'
    | 'EMPLOYEES_DIRECT_DEPOSIT_US'
    | 'EMPLOYEES_FEDERAL_US'
    | 'EMPLOYEES_PAYROLL_CA'
    | 'EMPLOYEES_PAYROLL_US'
    | 'EMPLOYEES_MAIN_CA'
    | 'EMPLOYEES_MAIN_US'
    | 'EMPLOYEES_SALARY'
    | 'EMPLOYEE_RATE_HISTORY'
    | 'EMPLOYEE_TAGS'
    | 'EMPLOYEE_STATE_TAX_SETUP'
    | 'GL_ACCOUNTS'
    | 'GL_BRANCH_ACCOUNTS'
    | 'GL_TRANSACTION_DETAILS'
    | 'JOBS'
    | 'JOBS_ACCOUNTING'
    | 'JOBS_GENERAL'
    | 'JOBS_RATE'
    | 'JOBS_PAYROLL'
    | 'JOBS_REFERENCE'
    | 'JOBS_REPORTING'
    | 'JOB_EXPENSES'
    | 'JOB_DETAILS'
    | 'JOB_DETAILS_ADDITIONAL'
    | 'JOB_DETAILS_ADJUSTMENT'
    | 'JOB_DETAILS_BUDGET'
    | 'PAYMENTS'
    | 'PAYMENTS_UPDATE'
    | 'PAYROLL_EMP_ITEMS'
    | 'PAYROLL_EMP_ITEMS_PTO_ACCRUAL'
    | 'Q_FLOORS'
    | 'SUPPLIERS'
    | 'SUPPLIERS_UPDATE'
    | 'SUPPLIER_TAGS'
    | 'TAX_AREA_SETUP';
  eventType: 'VALIDATE' | 'SUBMIT' | 'ROLLBACK' | 'COMMIT';
  user: User;
  userId: string;
  date: string;
  fileId: string;
  notes?: string;
  signee?: string;
  uuid: string;
};
export type PermissionLog = {
  action: object;
  user: User;
  userId: string;
  date: string;
  fileId: string;
  email: string;
  uuid: string;
};
export type ReadSheetValidityDto = {
  valid: boolean;
};
export type ReadSheetDataDto = {
  data: string[][];
};
export type AddRowsDto = {
  spreadsheetId: string;
  numberOfRows: number;
};
export type DatumWithErrors = {
  columnIndex: number;
  rowIndex: number;
  value: string;
  errors: string[];
};
export type TemplateSwitchValidateDto = {
  data: DatumWithErrors[][];
  headers: string[];
  sheetHeaders: string[];
  exceptionsLink: string;
  valid: boolean;
  cacheKey?: string;
};
export type TemplateSwitchFetchDto = {
  fileId: string;
  notes?: string;
  signee?: string;
};
export type CsvLinkDto = {
  data: string;
};
export type CompanyDto = {
  name: string;
  id: string;
  url: string;
};
export type MetadataDto = {
  companyName: string;
};
export const {
  useAuthControllerGetDmsTokenMutation,
  useDriveControllerGetFilesQuery,
  useDriveControllerGetTemplatesQuery,
  useDriveControllerCloneTemplatesMutation,
  useDriveControllerAddPermissionsMutation,
  useDriveControllerRemovePermissionsMutation,
  useDriveControllerDeleteFilesMutation,
  useDriveControllerLockFileMutation,
  useDriveControllerUnlockFileMutation,
  useDriveControllerCreateTemplateMutation,
  useDriveControllerReplaceTemplateMutation,
  useMetricsControllerUpdateUserInfoMutation,
  useMetricsControllerGetImportLogsQuery,
  useMetricsControllerGetPermissionLogsQuery,
  useSheetsControllerReadSheetValidationQuery,
  useSheetsControllerReadSheetQuery,
  useSheetsControllerAddRowsMutation,
  useTemplateSwitchControllerValidateQuery,
  useTemplateSwitchControllerSubmitMutation,
  useTemplateSwitchControllerCommitMutation,
  useTemplateSwitchControllerRollbackMutation,
  useCsvControllerGetTemplatesByModuleQuery,
  useCsvControllerGetTemplateCsvsQuery,
  useDmsControllerFindAllQuery,
  useMetadataControllerFindAllQuery,
} = injectedRtkApi;
