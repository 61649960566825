import { ColumnDef, Tooltip } from '@plexxis/ui';
import { DatumWithErrors } from '../../api/imports-api/imports-api';

export const usePrepareColumns = (sheetHeaders: string[], headers: string[]): ColumnDef<DatumWithErrors[]>[] => {
  const columns = sheetHeaders.map((header, index) => ({
    accessorFn: (row: any) => row[index],
    id: index.toString(),
    header: () => (
      <Tooltip title={headers[index]}>
        <span style={{ padding: 'none', margin: 'auto' }}>{header}</span>
      </Tooltip>
    ),
    cell: (props: any) => {
      const { value, rowIndex, errors } = props.getValue();
      const cellStyle = {
        backgroundColor: errors.length > 0 ? '#e43a48' : '',
        paddingLeft: '2px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      };

      return (
        <>
          {errors.length > 0 ? (
            <Tooltip title={`Row ${rowIndex}: ${errors.join('\n')}`} style={{ whiteSpace: 'pre-line' }}>
              <div style={cellStyle}>{value}</div>
            </Tooltip>
          ) : (
            <div style={cellStyle}>{value}</div>
          )}
        </>
      );
    },
  }));
  columns.unshift(columns.pop()!);
  return columns;
};
