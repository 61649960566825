import { createSlice } from '@reduxjs/toolkit';
import { allThemes } from '@plexxis/ui';

const initialState = (localStorage.getItem('theme') as keyof typeof allThemes) || 'insight';

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(_, action) {
      return action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
