import styled from '@emotion/styled';
import { TrashIcon, Pencil1Icon, PlusIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { Button, Input, InputProps } from '@plexxis/ui';
import { toast } from 'react-toastify';

export interface ListInputProps extends InputProps {
  items: string[];
  setItems: (value: string[]) => void;
}

const StyledListInput = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 300,
  maxWidth: 'min-content',
}));

const ListHeader = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '0.5rem',
  paddingBottom: '0.5rem',
  width: '100%',
});
const StyledText = styled.span((props) => ({
  ...props.theme.typography.body_l,
  color: props.theme.palette.text.default,
}));
const List = styled.ul({
  margin: '0',
  padding: '0.25rem 0rem 0.25rem 1rem',
  listStyle: 'none',
  maxHeight: '200px',
  overflowY: 'auto',
});

const ListItem = styled.li({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '2px 0',
});

const ListButtons = styled.div({
  display: 'flex',
  paddingRight: '10px',
  gap: '2px',
});
export const ListInput = ({ items, setItems, ...inputProps }: ListInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const { onChange, onKeyDown, value, ...sanitizedProps } = inputProps;
  const [previousValue, setPreviousValue] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleAdd = (itemToAdd: string) => {
    // Don't allow duplicates.
    if (items.find((item) => item === itemToAdd)) {
      toast.error('Email has already been entered.');
      return;
    }
    const mailFormat = /\S+@\S+\.\S+/;
    if (!mailFormat.test(itemToAdd)) {
      toast.error('Invalid email format.');
      return;
    }

    if (itemToAdd) {
      let updatedItems: string[];

      if (previousValue) {
        updatedItems = items.map((item) => (item === previousValue ? itemToAdd : item));
        setPreviousValue('');
      } else {
        updatedItems = [...items, itemToAdd];
      }
      setItems(updatedItems);
      setInputValue('');
    }
  };

  const handleEdit = (itemToEdit: string) => {
    if (!inputValue) {
      setPreviousValue(itemToEdit);
      setInputValue(itemToEdit);
    }
  };

  const handleDelete = (itemToDelete: string) => {
    const updatedItems = items.filter((item) => item !== itemToDelete);
    setItems(updatedItems);
    if (previousValue) {
      setInputValue('');
      setPreviousValue('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAdd(inputValue);
    }
  };
  return (
    <StyledListInput>
      <ListHeader>
        <Input
          value={inputValue}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          {...sanitizedProps}
        />
        {/* Change back to icon button */}
        <Button
          children={<PlusIcon />}
          size={24}
          style={{ marginBottom: 8 }}
          onClick={() => handleAdd(inputValue)}
          type="button"
        />
      </ListHeader>
      {items && items.length > 0 && (
        <List>
          {items.map((item) => (
            <ListItem key={item}>
              <StyledText>{item}</StyledText>
              <ListButtons>
                <Button
                  icon={<Pencil1Icon />}
                  type="button"
                  size={24}
                  variant="border"
                  onClick={() => handleEdit(item)}
                />
                <Button
                  icon={<TrashIcon />}
                  type="button"
                  size={24}
                  variant="delete"
                  onClick={() => handleDelete(item)}
                />
              </ListButtons>
            </ListItem>
          ))}
        </List>
      )}
    </StyledListInput>
  );
};
