import styled from '@emotion/styled';
import {
  CreateTemplateDto,
  useDriveControllerGetTemplatesQuery,
  useDriveControllerReplaceTemplateMutation,
} from '../api/imports-api/imports-api';
import { useEffect, useState } from 'react';
import { Button, Input, Select } from '@plexxis/ui';
import { DriveFile, DriveFileList } from './types/google-types';
import { toast } from 'react-toastify';

const StyledWrapper = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 5px',
  gap: '2rem',
  overflowX: 'scroll',
  minHeight: '50rem',
  width: '50rem',
  color: props.theme.palette.text.default,
  ...props.theme.typography.body_m,
}));

interface TemplateObject {
  id: string;
  name: string;
  code: string;
  module?: string;
}

export const AdminPanel = () => {
  const [templateObjectOptions, setTemplateObjectOptions] = useState<TemplateObject[]>([]);
  const [newTemplateId, setNewTemplateId] = useState<string>('');
  const [newTemplateName, setNewTemplateName] = useState<string>('');
  const [selectedTemplateCode, setSelectedTemplateCode] = useState<string | undefined>(undefined);

  const { data } = useDriveControllerGetTemplatesQuery();
  const [triggerUpdate] = useDriveControllerReplaceTemplateMutation();

  useEffect(() => {
    let templateObjects: TemplateObject[] = [];
    if (data) {
      const files = (data as DriveFileList).files || [];
      files.forEach((file: DriveFile) => {
        if (!file.properties?.templateCode) return;
        templateObjects.push({
          id: file.id,
          name: file.name,
          code: file.properties.templateCode,
          module: file.properties?.module,
        });
      });
      setTemplateObjectOptions(templateObjects);
    }
  }, [data]);

  const handleUpdateTemplate = async () => {
    if (!selectedTemplateCode || !newTemplateId) {
      toast.error('Please select a template and enter a new template ID');
      return;
    }

    // DTO type is curently broken. Name is not actually a required field.
    // This is a workaround until we fix dto and rerun codegen.
    const createTemplateDto = {
      fileId: newTemplateId,
      name: newTemplateName.length > 0 ? newTemplateName : undefined,
      templateCode: selectedTemplateCode as CreateTemplateDto['templateCode'],
    } as CreateTemplateDto;

    try {
      toast.info('Updating template...');
      await triggerUpdate({
        createTemplateDto,
      }).unwrap();
      toast.success(`${selectedTemplateCode} template updated!`);
      setNewTemplateId('');
      setNewTemplateName('');
    } catch (e) {
      toast.error(`Template update failed ${e}`);
    }
  };

  return (
    <StyledWrapper>
      <h2>Modify Templates</h2>
      <Select
        label="Template Code"
        options={templateObjectOptions.map((templateObject) => ({
          value: templateObject.code,
          label: templateObject.code,
        }))}
        onChange={({ value }) => {
          setNewTemplateId('');
          setNewTemplateName('');
          setSelectedTemplateCode(value);
        }}
        value={selectedTemplateCode ? { value: selectedTemplateCode, label: selectedTemplateCode } : undefined}
      />
      {selectedTemplateCode && (
        <>
          <div style={{ display: 'flex', gap: '3rem' }}>
            <span>
              Current Template ID: <h4>{selectedTemplateCode}</h4>
            </span>
            <span>
              Current Template Name:
              <h4>
                {templateObjectOptions.find((templateObject) => templateObject.code === selectedTemplateCode)?.name}
              </h4>
            </span>
          </div>

          <Input
            label="New Template ID (In google drive URL)"
            value={newTemplateId}
            onChange={(e) => {
              setNewTemplateId(e.target.value);
            }}
          />
          <Input
            label="(Optional) New template name"
            value={newTemplateName}
            onChange={(e) => {
              setNewTemplateName(e.target.value);
            }}
          />
          <Button text="Update Template" onClick={handleUpdateTemplate} />
        </>
      )}
    </StyledWrapper>
  );
};
