import { Input } from '@plexxis/ui';

interface AddRowsInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
}

export const AddRowsInput = ({ inputValue, setInputValue }: AddRowsInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    let value = e.target.value;
    if (value === '' || regex.test(value)) {
      if (Number(value) > 25000) value = '25000';
      setInputValue(value);
    }
  };
  return (
    <Input
      type="number"
      value={inputValue}
      onChange={(e) => handleChange(e)}
      onKeyDown={(e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()}
      placeholder="Default is 100..."
      label="Add Extra Rows (1-25000)"
    />
  );
};
