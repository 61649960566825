import styled from '@emotion/styled';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Select, Button, Table, Loader } from '@plexxis/ui';
import { ColumnDef } from '@tanstack/react-table';
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon, LockClosedIcon, ReloadIcon } from '@radix-ui/react-icons';
import { DriveFile, DriveFileList, SelectOption } from './types/google-types';
import {
  useDriveControllerGetFilesQuery,
  useDriveControllerUnlockFileMutation,
  useDriveControllerLockFileMutation,
  useLazyTemplateSwitchControllerValidateQuery,
  useLazySheetsControllerReadSheetValidationQuery,
  useTemplateSwitchControllerCommitMutation,
  useTemplateSwitchControllerRollbackMutation,
  useTemplateSwitchControllerSubmitMutation,
} from '../api/imports-api/enhanced-api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setSelectedFile } from '../selectedFileSlice';
import { DatumWithErrors } from '../api/imports-api/imports-api';
import { usePrepareColumns } from './hooks/usePrepareColumns';
import { AlertButton } from './AlertButton';
import { ImportDialog } from './ImportDialog';
import { toast } from 'react-toastify';
import { useProgressManager } from './hooks/useProgressManager';
import { LoadingBar } from './LoadingBar';

type ActionFn = (description?: string | undefined, signoff?: string | undefined) => Promise<void>;

const StyledValidatorContainer = styled.div(() => ({
  display: 'grid',
  grid: 'max-content 1fr / 3fr 1fr',
  height: '80%',
  gap: '10px',
  margin: '10px',
}));

const StyledOptions = styled.div(() => ({
  display: 'grid',
  grid: 'min-content / min-content 1fr min-content',
  gridColumn: '1 / 3',
  gap: '10px',
}));

const StyledPageNumber = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '24px',
}));

const StyledAlert = styled.div((props) => ({
  ...props.theme.typography.headline_5,
  gap: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  alignItems: 'center',
}));

const StyledSubmitButton = styled.div(() => ({
  marginLeft: 'auto',
  gridColumn: '2/3',
  gridRow: '3 / 4',
  display: 'flex',
  gap: '2rem',
}));
const StyledConfirmationButtons = styled.div(() => ({
  marginLeft: 'auto',
  gridColumn: '2/3',
  gridRow: '3 / 4',
  display: 'flex',
  gap: '20px',
}));

const TableWrapper = styled.div(() => ({
  // Remove artifacts from ui kit's table
  overflowX: 'auto',
  overflowY: 'scroll',
  maxHeight: '60vh',
  tbody: {
    '&:before': {
      content: 'none',
    },
    overflowX: 'hidden',
  },
  td: {
    '&:first-of-type': {
      padding: '0px',
    },
  },
  thead: {
    position: 'sticky',
    top: '0',
  },
}));

const StyledPaginator = styled.div((props) => ({
  color: props.theme.palette.text.default,
  gridRow: '3 / 4',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
}));

export const ValidatorContainer = () => {
  const [fileOptions, setFileOptions] = useState<SelectOption[]>([]);
  const [validating, setValidating] = useState(false);
  const [tableData, setTableData] = useState<DatumWithErrors[][]>([]);
  const [tableHeaders, setTableHeaders] = useState<ColumnDef<DatumWithErrors[]>[]>([]);
  const { fileId } = useSelector((state: RootState) => state.selectedFile);
  const [currentFile, setCurrentFile] = useState<DriveFile>();
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [rollbackDialogOpen, setRollbackDialogOpen] = useState(false);
  const [commitDialogOpen, setCommitDialogOpen] = useState(false);

  const { currentData: filesData } = useDriveControllerGetFilesQuery();
  const [lock, lockResult] = useDriveControllerLockFileMutation();
  const [unlock, unlockResult] = useDriveControllerUnlockFileMutation();
  const [validate, validationResult] = useLazyTemplateSwitchControllerValidateQuery();
  const [page, setPage] = useState(1);
  const [excelValidate, excelValidateResult] = useLazySheetsControllerReadSheetValidationQuery();
  const [submit, submitResult] = useTemplateSwitchControllerSubmitMutation();
  const [commit, commitResult] = useTemplateSwitchControllerCommitMutation();
  const [rollback, rollbackResult] = useTemplateSwitchControllerRollbackMutation();

  const tableAnchor = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let childrenFiles: SelectOption[] = [];
    if (filesData) {
      const res = (filesData as DriveFileList).files || [];
      res.forEach((file: DriveFile) => {
        childrenFiles.push({ value: file.id, label: file.name });
      });
    }
    setFileOptions(childrenFiles);
  }, [filesData]);

  useEffect(() => {
    if (fileId && filesData) {
      excelValidate({ spreadsheetId: fileId }, false);
      setCurrentFile((filesData as DriveFileList).files?.find((file) => file.id === fileId));
    } else {
      setCurrentFile(undefined);
    }
  }, [fileId, filesData]);

  const handleUnlock = async () => {
    if (!currentFile) return;
    if (currentFile.appProperties?.submission === 'OPEN') await unlock({ fileLockDto: { fileId: currentFile.id } });
    setValidating(false);
  };

  const handleValidate = async (page?: number, cache?: boolean) => {
    if (!currentFile) return;
    if (!cache) setPage(1);
    if (!(await excelValidate({ spreadsheetId: currentFile.id }).unwrap()).valid) return;
    if (currentFile?.appProperties?.locked === 'false') {
      await lock({ fileLockDto: { fileId: currentFile.id } });
    }
    const currentCacheKey = cache ? validationResult.data?.cacheKey : undefined;
    const res = await validate({ fileId: currentFile.id, page, cacheKey: currentCacheKey }, false).unwrap();
    setTableHeaders(usePrepareColumns(res.sheetHeaders, res.headers));
    setTableData(res.data);
    setValidating(true);
  };

  const handleSubmit: ActionFn = async (notes?: string, signee?: string) => {
    if (!currentFile) return;
    const submission = currentFile.appProperties?.submission;
    notes = notes ? notes : undefined;
    signee = signee ? signee : undefined;
    if (submission === 'COMPLETE') return;
    if (submission === 'OPEN') {
      await submit({ templateSwitchFetchDto: { fileId: currentFile.id, notes, signee } }).unwrap();
      toast.success('File successfully imported');
    }
  };

  const handleRollback: ActionFn = async (notes?: string, signee?: string) => {
    if (!currentFile) return;
    notes = notes ? notes : undefined;
    signee = signee ? signee : undefined;
    await rollback({ templateSwitchFetchDto: { fileId: currentFile.id, notes, signee } }).unwrap();
    toast.success('File successfully rolled back');
  };
  const handleCommit: ActionFn = async (notes?: string, signee?: string) => {
    if (!currentFile) return;
    notes = notes ? notes : undefined;
    signee = signee ? signee : undefined;
    await commit({ templateSwitchFetchDto: { fileId: currentFile.id, notes, signee } });
    toast.success('File successfully committed');
  };

  const handleDownload = async () => {
    window.open(validationResult.data?.exceptionsLink, '_blank');
  };

  const { progressList, reset } = useProgressManager();
  const fileProgress = fileId ? progressList[fileId!] : undefined;

  const columns = useMemo((): ColumnDef<DatumWithErrors[]>[] => tableHeaders, [tableHeaders]);

  let buttonType = !excelValidateResult.data?.valid ? 'warning' : undefined;

  const valid = validationResult.data?.valid;
  if (validationResult.isSuccess && validating) {
    buttonType = valid ? 'success' : 'error';
  }
  const validationLoading =
    excelValidateResult.isLoading ||
    excelValidateResult.isFetching ||
    validationResult.isLoading ||
    validationResult.isFetching ||
    lockResult.isLoading ||
    unlockResult.isLoading;
  const submissionPending = currentFile?.appProperties?.submission === 'PENDING';
  const submissionCompleted = currentFile?.appProperties?.submission === 'COMPLETED';

  return (
    <StyledValidatorContainer>
      <StyledOptions>
        <Select
          isDisabled={validating || validationLoading}
          options={fileOptions}
          value={fileOptions.find((el) => el.value === fileId)}
          onChange={async (e) => {
            if (e?.value) {
              dispatch(setSelectedFile({ fileId: e.value }));
            }
          }}
          placeholder="Select File..."
          isSearchable={true}
        ></Select>
        {!excelValidateResult.isFetching && !excelValidateResult.isUninitialized && (
          <StyledAlert>
            <AlertButton type={buttonType} fileId={fileId}></AlertButton>
            {buttonType === 'warning' && (
              <Button
                onClick={() => {
                  if (fileId) {
                    excelValidate({ spreadsheetId: fileId });
                  }
                }}
              >
                <ReloadIcon />
              </Button>
            )}
            {buttonType === 'error' && (
              <Button onClick={handleDownload}>
                <DownloadIcon />
              </Button>
            )}
          </StyledAlert>
        )}
        <Button
          disabled={!fileId || !excelValidateResult.data?.valid || submissionPending || submissionCompleted}
          loading={validationLoading}
          style={{ marginLeft: 'auto', gridColumn: '4 / 5' }}
          onClick={() => (validating ? handleUnlock() : handleValidate())}
        >
          {validating ? <LockClosedIcon /> : 'Validate'}
        </Button>
      </StyledOptions>
      {
        <>
          {!valid && validating && (
            <>
              <TableWrapper>
                <Table
                  data={tableData}
                  columns={columns}
                  styles={{
                    body: {
                      maxHeight: '60vh',
                      overflowY: 'scroll',
                      scrollbarGutter: 'stable',
                    },

                    headCell: {
                      borderRight: '1px solid lightgray',
                      textAlign: 'center',
                      padding: '0px 6px',
                    },
                    headRow: { width: 'calc(100% - 6px)' },
                    row: {
                      width: '100%',
                      overflowX: 'visible',
                    },
                    cell: {
                      border: '1px solid lightgray',
                    },
                    table: {
                      overflowX: 'visible',
                      maxHeight: '60vh',
                    },
                  }}
                  selected={[]}
                  extraTopNode={<div ref={tableAnchor} style={{ visibility: 'hidden' }}></div>}
                ></Table>
              </TableWrapper>
              <StyledPaginator>
                <Button
                  disabled={page === 1 || validationLoading || !validating}
                  onClick={() => {
                    setPage(Math.max(1, page - 1));
                    handleValidate(Math.max(1, page - 1), true);
                    tableAnchor?.current?.scrollIntoView({ block: 'center' });
                  }}
                  icon={<ChevronLeftIcon />}
                />
                <StyledPageNumber>{validationLoading ? <Loader size={'s'} /> : page}</StyledPageNumber>
                <Button
                  disabled={(tableData?.length || 0) < 100 || validationLoading || !validating}
                  onClick={() => {
                    setPage(page + 1);
                    handleValidate(page + 1, true);
                    tableAnchor?.current?.scrollIntoView({ block: 'center' });
                  }}
                  icon={<ChevronRightIcon />}
                />
              </StyledPaginator>
            </>
          )}
          {submissionPending ? (
            <StyledConfirmationButtons>
              <Button variant="delete" onClick={() => setRollbackDialogOpen(true)} loading={rollbackResult.isLoading}>
                ROLLBACK
              </Button>
              <ImportDialog
                variant="rollback"
                open={rollbackDialogOpen}
                setOpen={setRollbackDialogOpen}
                fileName={fileOptions.find((el) => el.value === fileId)?.label}
                action={handleRollback}
                loading={rollbackResult.isLoading}
              ></ImportDialog>
              <Button onClick={() => setCommitDialogOpen(true)} loading={commitResult.isLoading}>
                COMMIT
              </Button>
              <ImportDialog
                variant="commit"
                open={commitDialogOpen}
                setOpen={setCommitDialogOpen}
                fileName={fileOptions.find((el) => el.value === fileId)?.label}
                action={handleCommit}
                loading={commitResult.isLoading}
              ></ImportDialog>
            </StyledConfirmationButtons>
          ) : (
            <StyledSubmitButton>
              {fileProgress && fileProgress.status === 'progress' && !importDialogOpen && (
                <div style={{ width: '10rem', alignSelf: 'center' }}>
                  <LoadingBar progress={fileProgress} />
                </div>
              )}

              <Button
                disabled={!validating || !valid || submissionCompleted}
                type="submit"
                onClick={() => {
                  setImportDialogOpen(true);
                  reset();
                }}
                loading={submitResult.isLoading}
              >
                Import
              </Button>
              <ImportDialog
                variant="submit"
                open={importDialogOpen}
                setOpen={setImportDialogOpen}
                fileName={fileOptions.find((el) => el.value === fileId)?.label}
                action={handleSubmit}
                disabled={!validating || !valid || submissionCompleted}
                loading={submitResult.isLoading}
                progress={fileProgress}
              ></ImportDialog>
            </StyledSubmitButton>
          )}
        </>
      }
    </StyledValidatorContainer>
  );
};
