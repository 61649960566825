import { Button, Dialog, Input } from '@plexxis/ui';
import styled from '@emotion/styled';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { Progress } from './hooks/useProgressManager';
import { LoadingBar } from './LoadingBar';
import { useMetadataControllerFindAllQuery } from '../api/imports-api/imports-api';

type ImportDialogProps = {
  variant: 'submit' | 'rollback' | 'commit';
  open: boolean;
  setOpen: (open: boolean) => void;
  fileName?: string;
  action: (description?: string, signoff?: string) => Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  progress?: Progress;
};

type ImportDialogFragmentProps = {
  fileName?: string;
  companyName?: string;
};

const StyledDialogOptions = styled.div(() => ({
  display: 'flex',
  width: '100%',
  padding: '5px',
  borderRadius: '0px 0px 5px 5px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
}));
const StyledImportDialog = styled.div((props) => ({
  ...props.theme.typography.headline_5,
  color: props.theme.palette.text.default,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  borderRadius: 5,
  paddingTop: 5,
}));
const StyledWarning = styled.div((props) => ({
  color: props.theme.palette.status.warning,
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const StyledDescriptionSignoffs = styled.div(() => ({
  textAlign: 'left',
  width: '100%',
  gap: '10px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CommitFragment = ({ fileName, companyName }: ImportDialogFragmentProps) => (
  <>
    <StyledWarning>
      <ExclamationTriangleIcon width={25} height={25} />
      <h3>Commiting Data Import</h3>
    </StyledWarning>
    <p>You are about to commit the changes from file</p>
    <b style={{ fontSize: 20 }}>{fileName}</b>
    <p>for the company</p>
    <b style={{ fontSize: 22 }}>{companyName}</b>
    <p>
      <b>This operation is NON-REVERSIBLE.</b>
    </p>
  </>
);

const RollbackFragment = ({ fileName, companyName }: ImportDialogFragmentProps) => (
  <>
    <StyledWarning>
      <ExclamationTriangleIcon width={25} height={25} />
      <h3>Rolling Back Import</h3>
    </StyledWarning>
    <p>You are about to roll back the changes from:</p>
    <b style={{ fontSize: 20 }}>{fileName}</b>
    <p>for the company:</p>
    <p>
      <b style={{ fontSize: 22 }}>{companyName}</b>
    </p>
  </>
);

const ImportFragment = ({ fileName, companyName }: ImportDialogFragmentProps) => (
  <>
    <StyledWarning>
      <ExclamationTriangleIcon width={25} height={25} />
      <h2>Importing Data</h2>
    </StyledWarning>
    <p>You are about to import data from:</p>
    <b style={{ fontSize: 20 }}>{fileName}</b>
    <p>into the company:</p>
    <p>
      <b style={{ fontSize: 22 }}>{companyName}</b>
    </p>
  </>
);

const dialogVariants = {
  commit: CommitFragment,
  rollback: RollbackFragment,
  submit: ImportFragment,
};

const buttonTextVariants = {
  commit: 'Commit',
  rollback: 'Rollback',
  submit: 'Import',
};

export const ImportDialog = (props: ImportDialogProps) => {
  const { open, setOpen, fileName, action, disabled, loading, variant, progress } = props;

  const { data: metadata } = useMetadataControllerFindAllQuery();

  const companyName = metadata?.companyName || '';

  const [description, setDescription] = useState('');
  const [signoff, setSignoff] = useState('');

  const handleAction = async () => {
    await action(description, signoff);
    setOpen(false);
  };

  const DialogComponent = dialogVariants[variant];
  const buttonText = buttonTextVariants[variant];
  const displayDescriptionSignoff = !(variant === 'submit');

  return (
    <Dialog open={open} onOpenChange={setOpen} height="min-content" style={{ minWidth: '400px' }} padding="15px">
      <StyledImportDialog>
        <div style={{ padding: '0px 15px' }}>
          <DialogComponent fileName={fileName} companyName={companyName} />
        </div>
        <p>
          <b>Are you sure?</b>
        </p>
        {displayDescriptionSignoff && (
          <StyledDescriptionSignoffs>
            <Input
              label="Description"
              value={description}
              multiline={true}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add a description..."
            />
            <Input
              label="Signoff"
              value={signoff}
              onChange={(e) => setSignoff(e.target.value)}
              placeholder="Add a signee..."
            />
          </StyledDescriptionSignoffs>
        )}

        <StyledDialogOptions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={disabled} onClick={handleAction} loading={loading} variant="delete">
            {buttonText}
          </Button>
        </StyledDialogOptions>
        <div style={{ height: '1rem', paddingTop: '1rem', width: '8rem' }}>
          {progress && <LoadingBar progress={progress} />}
        </div>
      </StyledImportDialog>
    </Dialog>
  );
};
