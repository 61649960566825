import { LoggedInCheckpoint } from './auth/LoggedInCheckpoint';
import { Layout } from './components/Layout';
import { ThemeProvider, Global, css } from '@emotion/react';
import { allThemes } from '@plexxis/ui';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { AuthProvider } from '@plexxis/react-auth-provider';
import { useAuthControllerGetDmsTokenMutation } from './api/imports-api/imports-api';

const redirectPath = window.location.origin;
const pasUiURL = import.meta.env.VITE_PAS_UI_URL || '__Xx__VITE_PAS_UI_URL__xX__';

function App() {
  const [getJwtMutation] = useAuthControllerGetDmsTokenMutation();

  const selectedTheme = useSelector((state: RootState) => state.theme) as keyof typeof allThemes;

  const getToken = async (code: string, codeVerifier: string) => {
    return await getJwtMutation({ getTokenDto: { code, codeVerifier } }).unwrap();
  };

  return (
    <ThemeProvider theme={allThemes[selectedTheme]}>
      <Global
        styles={{
          body: {
            margin: '0',
            backgroundColor: allThemes[selectedTheme].palette.background.default,
          },
        }}
      />
      <Global
        styles={css`
          ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }
          ::-webkit-scrollbar-corner {
            background: rgba(0, 0, 0, 0);
          }
          ::-webkit-scrollbar-track {
            background: ${allThemes[selectedTheme].palette.background.default};
            border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb {
            background: ${allThemes[selectedTheme].palette.primary.main};
            border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: ${allThemes[selectedTheme].palette.primary.hover};
          }
        `}
      />

      <AuthProvider redirectPath={redirectPath} loginPageURL={pasUiURL} getTokensFunction={getToken}>
        <LoggedInCheckpoint>
          <Layout />
        </LoggedInCheckpoint>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
