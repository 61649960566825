import { enhancedApi } from './imports-api';

export const taggedApi = enhancedApi.enhanceEndpoints({
  addTagTypes: ['Files', 'Companies'],
  endpoints: {
    driveControllerGetFiles: {
      providesTags: () => [{ type: 'Files' }, { type: 'Files' }], // Add tags to the getFiles endpoint
    },
    metricsControllerGetImportLogs: {
      providesTags: ['Files'],
    },
    metricsControllerGetPermissionLogs: {
      providesTags: ['Files'],
    },
    driveControllerAddPermissions: {
      invalidatesTags: ['Files'], // Invalidate tags for the driveControllerAddPermissions endpoint
    },
    driveControllerRemovePermissions: {
      invalidatesTags: ['Files'], // Invalidate tags for the driveControllerRemovePermissions endpoint
    },
    driveControllerCloneTemplates: {
      invalidatesTags: [{ type: 'Companies' }, { type: 'Files' }],
    },
    driveControllerLockFile: {
      invalidatesTags: ['Files'],
    },
    driveControllerUnlockFile: {
      invalidatesTags: ['Files'],
    },
    driveControllerDeleteFiles: {
      invalidatesTags: ['Files'],
    },
    templateSwitchControllerSubmit: {
      invalidatesTags: ['Files'],
    },
    templateSwitchControllerRollback: {
      invalidatesTags: ['Files'],
    },
    templateSwitchControllerCommit: {
      invalidatesTags: ['Files'],
    },
    driveControllerReplaceTemplate: {
      invalidatesTags: ['Files'],
    },
    metadataControllerFindAll: {
      keepUnusedDataFor: 3600000,
    },
  },
});

export const {
  useDriveControllerGetFilesQuery,
  useDriveControllerAddPermissionsMutation,
  useDriveControllerRemovePermissionsMutation,
  useDriveControllerLockFileMutation,
  useDriveControllerUnlockFileMutation,
  useMetricsControllerGetImportLogsQuery,
  useMetricsControllerGetPermissionLogsQuery,
  useTemplateSwitchControllerSubmitMutation,
  useTemplateSwitchControllerCommitMutation,
  useTemplateSwitchControllerRollbackMutation,
  useLazyTemplateSwitchControllerValidateQuery,
  useLazySheetsControllerReadSheetValidationQuery,
  useLazyCsvControllerGetTemplateCsvsQuery,
  useCsvControllerGetTemplateCsvsQuery,
} = taggedApi;
