import { createSlice } from '@reduxjs/toolkit';

export type SelectedFileState = {
  fileId?: string;
};

const initialState: SelectedFileState = {
  fileId: undefined,
};

const selectedFileSlice = createSlice({
  name: 'selectedFile',
  initialState,
  reducers: {
    setSelectedFile(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSelectedFile } = selectedFileSlice.actions;
export default selectedFileSlice.reducer;
