import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { emptySplitApi } from './api/imports-api/importsBaseApi';
import { taggedApi as importsApi } from './api/imports-api/enhanced-api';
import themeSlice from './themeSlice';
import tabSlice from './tabSlice';
import selectedFileSlice from './selectedFileSlice';
import { rtkQueryErrorLogger } from './api/imports-api/error-logger';
import userSlice from './userSlice';

export const store = configureStore({
  reducer: {
    [importsApi.reducerPath]: importsApi.reducer,
    theme: themeSlice,
    tab: tabSlice,
    selectedFile: selectedFileSlice,
    user: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(emptySplitApi.middleware).concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
