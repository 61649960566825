import styled from '@emotion/styled';
import { IconButton, Loader } from '@plexxis/ui';
import { TrashIcon } from '@radix-ui/react-icons';
import { DrivePermission } from './types/google-types';

export interface PermissionProps {
  permission: DrivePermission;
  handleDelete: (id: string) => void;
  loading: boolean;
}

const StyledPermission = styled.li((props) => ({
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '400px',
  gap: '10px',
  margin: '0 0 5px 0',

  ...props.theme.typography.body_s,
}));
const StyledImage = styled.img(() => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
}));
const StyledName = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const StyledPair = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

export const Permission = ({ permission, handleDelete, loading }: PermissionProps) => {
  return (
    <StyledPermission>
      <StyledPair>
        <StyledImage src={permission.photoLink!} />
        <StyledName>
          <span>
            <b>{permission.displayName}</b>
          </span>
          <span>{permission.emailAddress}</span>
        </StyledName>
      </StyledPair>

      <span>{permission.role}</span>
      <IconButton
        children={loading ? <Loader size={'s'} /> : <TrashIcon />}
        size={26}
        onClick={() => handleDelete(permission.id!)}
      ></IconButton>
    </StyledPermission>
  );
};
